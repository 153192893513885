<script>
export default {
  props: {
    params: {
      type: Object,
    },
    indexRequest: {
      type: Function,
      required: true,
    },
  },
  data() {
    return {
      paginator: { data: [] },
      pagination: {
        page: 1,
        sortBy: undefined,
        sortDesc: undefined,
        perPage: 25,
        params: undefined,
      },
      loaded: false,
      isLoading: false,
      callTimeout: null,
      error: null,
      overwriteData: true,
    };
  },
  watch: {
    search: {
      handler() {
        if (this.callTimeout !== null) clearTimeout(this.callTimeout);
        this.callTimeout = setTimeout(() => this.reloadPaginator(), 300);
      },
    },
    params() {
      if (this.callTimeout !== null) clearTimeout(this.callTimeout);
      this.callTimeout = setTimeout(() => this.reloadPaginator(), 100);
    },
  },
  methods: {
    /**
     * Update only the page of the pagination object
     * @param page
     */
    updatePaginationPage(page) {
      this.updatePagination({
        page: page,
        sortBy: [this.pagination.sortBy],
        sortDesc: [this.pagination.sortDesc],
        params: this.params,
      });
    },
    /**
     * Update the pagination object and then reload the paginated resource with the new pagination object.
     * With a new sort, or a new page
     * @param sortBy
     * @param sortDesc
     * @param page
     * @param itemsPerPage
     * @param params
     *
     * @TODO this is still a vuetify way of pagination, this should be a generic way. And the vuetify way should be in
     * KCrudTable.old.vue
     */
    updatePagination({
      sortBy,
      sortDesc,
      page,
      itemsPerPage,
      params,
    } = {}) {
      if (sortBy || sortDesc || page || itemsPerPage || params) {
        this.pagination = {
          page: page || this.pagination.page,
          perPage: itemsPerPage || this.pagination.perPage,
          sortBy: sortBy && sortBy.length ? sortBy[0] : undefined,
          sortDesc: sortDesc && sortDesc.length && sortBy && sortBy.length && sortBy[0] ? sortDesc[0] : undefined,
          params,
        };
      }

      return this.reloadPaginator();
    },
    /**
     * Call the loadPaginator with the page and sorter saved in the pagination object
     */
    reloadPaginator() {
      this.loadPaginator(this.pagination.page, this.pagination.sortBy, this.pagination.sortDesc, this.params);
    },

    async loadPaginator(page, sortBy, sortDesc, params) {
      try {
        this.isLoading = true;

        const requestParameters = {
          page,
          perPage: this.pagination.perPage,
          search: this.search,
          sortBy: sortBy || this.sortBy,
          descending: sortDesc,
          params,
        };

        const {
          data: {
            data,
            meta,
          },
        } = await this.indexRequest(requestParameters);
        this.paginator.data = this.overwriteData ? data : [...this.paginator.data, ...data];
        this.paginator.meta = meta;

        this.loaded = true; // first time loaded
        this.isLoading = false; // loading changes
        this.pagination.page = this.paginator.meta.currentPage;
        if (meta.currentPage > meta.lastPage) this.tablePage = meta.lastPage;
      } catch (error) {
        this.error = error;
      }
    },
  },
};
</script>

import { render, staticRenderFns } from "./KCrudTable.vue?vue&type=template&id=7fa367e5&scoped=true&"
import script from "./KCrudTable.vue?vue&type=script&lang=ts&"
export * from "./KCrudTable.vue?vue&type=script&lang=ts&"
import style0 from "./KCrudTable.vue?vue&type=style&index=0&id=7fa367e5&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "7fa367e5",
  null
  
)

export default component.exports

import Paginator from '@/application/mixins/Paginator.vue';
// @ts-ignore @todo, convert paginator mixin into typescript
import { translateField } from '@/application/util/translation.ts';
import { mapGetters } from 'vuex';
import Vue, { PropType } from 'vue';

import { DataTableHeader } from 'vuetify/types';

interface ComponentData {
  tablePage: number,
  paginationIsCalled: boolean
}

interface Header extends Omit<DataTableHeader, 'text'> {
  text?: string,
  language?: string
}

export default Vue.extend({
  name: 'KCrudTable',
  mixins: [Paginator],
  props: {
    headers: {
      type: Array as PropType<Header[]>,
      default: () => [],
    },
    resource: { // this is used to check for permissions
      type: String,
    },
    languagePrefix: {
      required: false,
      type: String,
      default: '',
    },
    search: {
      type: String,
      default: '',
    },
    showExpand: {
      type: Boolean,
      default: false,
    },
    canEdit: {
      type: Function as PropType<() => (item: Record<string, unknown>) => boolean>,
      default: () => true,
    },
    canDelete: {
      type: Function as PropType<() => (item: Record<string, unknown>) => boolean>,
      default: () => true,
    },
    sortBy: {
      type: String,
    },
    sortDesc: {
      type: Boolean,
    },
    noActions: {
      type: Boolean,
      default: false,
    },
    noDisabledDeleteTooltip: {
      type: Boolean,
      default: false,
    },
    noDisabledUpdateTooltip: {
      type: Boolean,
      default: false,
    },
    useNewPaginator: {
      type: Boolean,
      default: false,
    },
  },
  data: (): ComponentData => ({
    tablePage: 1,
    paginationIsCalled: false,
  }),
  computed: {
    ...mapGetters({
      can: 'authorisation/can',
    }),
    itemSlots(): unknown {
      return Object.fromEntries(Object.entries(this.$scopedSlots).filter(([name]) => name.startsWith('item.')));
    },
    disabledDeleteTooltip(): string {
      return this.$te(`${this.resource}.messages.deleteDisabled`) ? `${this.resource}.messages.deleteDisabled` : 'global.deleteDisabled';
    },
    disabledUpdateTooltip(): string {
      return this.$te(`${this.resource}.messages.updateDisabled`) ? `${this.resource}.messages.updateDisabled` : 'global.updateDisabled';
    },
    datatableHeaders(): DataTableHeader[] {
      const headers: DataTableHeader[] = [
        ...this.headers.map((header) => {
          return {
            ...header,
            text: header.text ?? (header.language ? translateField(header.language, this.languagePrefix) : translateField(header.value, this.languagePrefix)),
            class: this.createClassArray(header.class),
          };
        }),
      ];

      if (!this.noActions) {
        headers.push({
          value: 'actions',
          align: 'end',
          cellClass: 'no-table-separator',
          sortable: false,
          text: '',
        });
      }

      return headers;

    },
    hasRowClickListener(): boolean {
      return !!this.$listeners['click:row'];
    },
  },
  async created() {
    // @ts-ignore @todo, convert paginator mixin into typescript
    this.updatePagination();
  },
  methods: {
    /**
     * Because vuetify is updating the pagination onLoad we need to stop the first time from getting passed.
     * Using this "updatePagination" as the first call is not possible since this is only called after the initial data
     * load by vuetify.
     *
     * https://github.com/vuetifyjs/vuetify/issues/12865
     */
    handleUpdatePagination(): void {
      if (this.paginationIsCalled) {
        // @ts-ignore
        this.updatePagination(...arguments);
      }
      this.paginationIsCalled = true;
    },
    reload(): void {
      // @ts-ignore @todo, convert paginator mixin into typescript
      this.reloadPaginator();
    },
    permissionChecker(permission: string): boolean {
      if (!this.resource) {
        return false;
      }
      return this.can(`${this.resource}.${permission}`);
    },
    hasClickUpdateListener(type: string): boolean {
      return !!this.$listeners && !!this.$listeners[`click:${type}`];
    },
    createClassArray(extraClassList: string[] | string | undefined): string[] {
      const classList = ['text--secondary'];
      if (!extraClassList) {
        return classList;
      }
      if (Array.isArray(extraClassList)) {
        return classList.concat(extraClassList);
      }
      classList.push(extraClassList);
      return classList;
    },
  },
});

<template>
  <div id="page-users-list">
    <KCrudLayout :search.sync="searchQuery">
      <template #header>
        {{ $tc('department.title', 2) }}
      </template>
      <template #flow-actions>
        <v-btn
          color="primary"
          depressed
          tile
          @click="handleOpenCreateDialog"
        >
          {{ $t('actions.createResource', { resource: $tc('department.title', 1) }) }}
        </v-btn>
        <DepartmentForm
          v-model="createDialog"
          :location="location"
          :request="createRequest"
          :title="$tc('department.title',1)"
          :values="createFormValues"
          @change="$refs.table.reload()"
        />
      </template>
      <template #view.list>
        <k-crud-table
          ref="table"
          :headers="crudHeaders"
          :index-request="indexRequest"
          :search="searchQuery"
          language-prefix="department.fields"
          resource="department"
          @click:edit="openUpdate"
          @click:row="$router.push({ name: 'employer.location.department.show', params: { locationId: location.id, departmentId: $event.id } })"
        />
      </template>
    </KCrudLayout>
    <DepartmentForm
      v-model="updateDialog"
      :location="location"
      :request="updateRequest"
      :title="$tc('department.title',1)"
      :values="updateFormValues"
      is-update-form
      @change="$refs.table.reload()"
    />
  </div>
</template>

<script>
import eventBus from '@/application/eventBus.ts';
import Department from '@/application/models/Department.js';
import KCrudTable from '@/components/crud/KCrudTable.vue';
import KCrudLayout from '@/components/layout/KCrudLayout.vue';
import { create, index, show, update } from '@/modules/department/api/department.ts';
import DepartmentForm from '@/modules/department/components/DepartmentForm.vue';

export default {
  name: 'DepartmentIndex',
  components: {
    DepartmentForm,
    KCrudTable,
    KCrudLayout,
  },
  props: {
    location: {
      type: Object,
      required: true,
    },
    employer: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      searchQuery: '',
      updateDialog: false,
      createDialog: false,
      updateFormValues: new Department(),
      createFormValues: new Department(),
    };
  },
  computed: {
    crudHeaders() {
      return [
        {
          value: 'name',
          language: 'name',
        },
        {
          value: 'phone',
          language: 'contactDetail.phone',
        },
        {
          value: 'email',
          language: 'contactDetail.email',
        },
        {
          value: 'city',
          language: 'address.city',
        },
        {
          value: 'street',
          language: 'address.street',
        },
      ];
    },
  },
  created() {
    eventBus.$emit('setBreadcrumbs', [
      {
        exact: true,
        to: { name: 'employer.index' },
        text: this.$tc('location.title', 2),
      },
      {
        exact: true,
        to: { name: 'employer.show' },
        text: this.employer.name,
      },
      {
        exact: true,
        to: { name: 'employer.location.index' },
        text: this.$tc('location.title', 2),
      },
      {
        exact: true,
        to: { name: 'employer.location.show' },
        text: this.location.name,
      },
      {
        exact: true,
        to: { name: 'employer.location.department.index' },
        text: this.$tc('department.title', 2),
      },
    ]);
  },
  methods: {
    handleOpenCreateDialog() {
      this.createFormValues = new Department();
      this.createDialog = true;
    },
    updateRequest() {
      return update(this.updateFormValues);
    },
    createRequest() {
      return create(this.$route.params.locationId, this.createFormValues);
    },
    indexRequest(data) {
      return index(this.$route.params.locationId, data);
    },
    async openUpdate(item) {
      this.updateFormValues = new Department();
      const departmentShowRequest = await show(item.id);
      this.updateFormValues.mapResponse(departmentShowRequest.data.data);
      this.updateDialog = true;
    },
  },
};
</script>
